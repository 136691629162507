<template>
  <div class="vx-row flex center">
    <div class="vx-col w-full mb-base">
      <vx-card :title="$t('setWord.title')" :subtitle="$t('setWord.desc')" class="mb-base items-center sm:px-4 px-0">
        <div class="vx-row flex justify-content-between mx-2">
          <vs-col vs-type="flex" vs-justify="space-between">
            <!-- 選擇類型 select -->
            <vs-select v-model="selecttype" @change="selectType">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in hovers" />
            </vs-select>
            <!-- 匯入 button -->
            <vs-button :size="windowWidth < 512 ? 'small' :''" class="py-1 px-3" color="warning" @click="DialogVisibleWord=true">
              <feather-icon style="vertical-align: middle;" icon="FilePlusIcon" />
              <b v-if="windowWidth >= 576" class="ml-1" style="font-size: 16px; vertical-align: middle;">{{$t('setWord.import')}}</b>
            </vs-button>
          </vs-col>
          <!-- 新增常用字句 textarea -->
          <vs-col class="my-4">
            <el-input type="textarea" rows="5" :placeholder="$t('setWord.addWord')" @keyup.enter="word" v-model="word" />
          </vs-col>
          <!-- 新增常用字句 button -->
          <vs-col vs-type="flex" vs-justify="flex-end">
            <vs-button :size="windowWidth < 576 ? 'small' :''" :disabled="word==''" @click="addWord">{{$t('setWord.addWord')}}</vs-button>
          </vs-col>
        </div>
        <vs-divider border-style="solid" color="primary" />
        <!-- 常用字句List -->
        <vs-row>
          <vs-col class="cd-form-group">
            <vs-list>
              <vs-list-item :class="windowWidth < 768 ? 'cd-list-item-sm w-full' : ''" :title="item.word" v-for="(item, index) in words" :key="index">
                <vs-col vs-type="flex" vs-justify="flex-end">
                  <feather-icon icon="EditIcon" class="m-1 cursor-pointer hover:text-primary" @click="showEditDialog(item)"></feather-icon>
                  <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="delWord(item)"></feather-icon>
                </vs-col>
              </vs-list-item>
              <vs-list-item v-show="words==''" :title="$t('setWord.noData')"></vs-list-item>
            </vs-list>
          </vs-col>
          <!-- 新增分頁 -->
          <vs-col vs-type="flex" vs-justify="center">
            <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(setwordTotal/15)" v-model="page" @change="pageChange(page)"></vs-pagination>
          </vs-col>
        </vs-row>
      </vx-card>
      <!-- 更新視窗 -->
      <vs-prompt
        :title="$t('setWord.edit')"
        :accept-text="$t('popup.save')"
        :cancel-text="$t('popup.cancel')"
        @accept="acceptEdit"
        :is-valid="editword!=''"
        :active.sync="activeEdit">
        <div class="con-exemple-prompt">
          <vs-textarea class="mx-1" :placeholder="$t('setWord.addWord')" @keyup.enter="acceptEdit" v-model="editword"/>
        </div>
      </vs-prompt>
      <!-- 匯入視窗 -->
      <el-dialog
        :visible.sync="DialogVisibleWord"
        :width="windowWidth > 768 ? '50%' :'80%'"
        center>
        <!--步驟-->
          <el-steps :active="importwordstep">
            <el-step :title="$t('setWord.download')" icon="el-icon-download">
            </el-step>
            <el-step :title="$t('setWord.uploadFile')" icon="el-icon-upload">
            </el-step>
            <el-step :title="$t('setWord.previewData')">
            </el-step>
            <el-step :title="$t('setWord.finish')">
            </el-step>
          </el-steps>
          <!--下載模板-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==1">
            <h1>
              <a href="https://upload.curdoctor.com.tw/excel_template/upload_setword_example.xlsx" style="color:black; text-decoration:none;">
                {{$t('setWord.downloadExample')}}<el-button type="info" icon="el-icon-download" circle></el-button>
              </a>
            </h1>
          </div>
          <!--上傳文件-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==2">
            <el-alert :title="$t('setWord.onlyExcelFile')" type="warning" show-icon style="margin:20px 0;"></el-alert>
            <el-upload
              class="upload-demo"
              drag
              action=""
              :multiple="false"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="ChangeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" v-html="$t('setWord.dragFile')"></div>
              <div class="el-upload__tip" slot="tip">
              </div>
            </el-upload>
          </div>
          <!--預覽資料-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==3">
            <el-table
              :data="previewworddata"
              height="250"
              border
              style="width: 100%">
              <el-table-column
                prop="type"
                :label="$t('setWord.doctorSOAP')">
                <template slot-scope="scope">
                  <div v-if="scope.row.type=='s'||scope.row.type=='o'||scope.row.type=='memop'||scope.row.type=='wd' ||scope.row.type=='diet' ">
                    <div style="text-align:center; font-size:15px;">{{ scope.row.type | type }}</div>
                  </div>
                  <div v-else>
                      <div style="text-align:center; font-size:15px;">{{ scope.row.type }}</div>
                      <el-alert :title="$t('setWord.noDoctorSOAP')" type="warning" show-icon style="margin:20px 0;"></el-alert>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="word"
                :label="$t('setWord.title')">
                <template slot-scope="scope">
                  <div  v-if="scope.row.word==''">
                    <div style="text-align:center; font-size:15px;">{{ scope.row.word }}</div>
                    <el-alert :title="$t('message.data_not_input')" type="warning" show-icon style="margin:20px 0;"></el-alert>
                  </div>
                  <div v-else>
                    <div style="text-align:center; font-size:15px;">{{ scope.row.word }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--完成-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==4">
            <h1>
              {{$t('message.upload_success')}}
            </h1>
          </div>
        <!--標籤-->
          <div style="text-align:center;">
          <vs-button :size="windowWidth > 576 ? '' :'small'" v-show="this.importwordstep==2 || this.importwordstep==3" @click="previousstep">{{$t('setWord.prevStep')}}</vs-button>
          <vs-button :size="windowWidth > 576 ? '' :'small'" v-show="this.importwordstep==1" @click="nextstep">{{$t('setWord.nextStep')}}</vs-button>
          <vs-button :size="windowWidth > 576 ? '' :'small'" v-show="this.importwordstep==3" @click="importword">{{$t('setWord.importMultiple')}}</vs-button>
          <vs-button :size="windowWidth > 576 ? '' :'small'" v-show="this.importwordstep==4" @click="closeDialogVisibleWord">{{$t('popup.confirm')}}</vs-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
  data() {
    return {
      val: '',
      close: '',
      editword: '',
      editid: 0,
      delid: 0,
      activeEdit: false,
      selecttype: 'wd',
      hovers:[
        {text:this.$t('setWord.soap_S'),value:'s'},
        {text:this.$t('setWord.soap_O'),value:'o'},
        {text:this.$t('setWord.soap_Memop'),value:'memop'},
        {text:this.$t('setWord.soap_WD'),value:'wd'},
        {text:this.$t('setWord.diet'),value:'diet'},
      ],
      word: '',
      //常用字句多筆匯入
      DialogVisibleWord:false,
      importwordstep:1,
      previewworddata:null,
      setwordPage: 1,
      setwordTotal: 0,
      page: 1,
    }
  },
  mounted() {
    this.fetchWords()
    this.pageChange(1)
  },
  filters:{
    type(type) {
      const statusMap = {
        's': '主觀項目 S',
        'o': '客觀項目 O',
        'memop':'醫事人員建議',
        'wd':'文字交談',
        'diet':'飲食建議'
      }
      return statusMap[type]
    },
  },
  computed: {
    windowWidth() {
        var windowwidth = this.$store.state.windowWidth
        return windowwidth
    },
    words() {
      let result
      if(this.selecttype=='s'){
        result = this.$store.getters.wordS
      }else if(this.selecttype=='o'){
        result = this.$store.getters.wordO
      }else if(this.selecttype=='memop'){
        result = this.$store.getters.wordMemo
      }else if(this.selecttype=='note'){
        result = this.$store.getters.wordNote
      }else if(this.selecttype=='wd'){
        result = this.$store.getters.wordWD
      }else if(this.selecttype=='diet'){
        result = this.$store.getters.wordDiet
      }
      return result
    }
  },
  methods: {
    // 開啟刪除視窗
    delWord(item){
      var _self = this
      _self.delid = item.id
      _self.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: _self.$t('popup.confirm'),
        text: _self.$t('message.confirm_delete_record'),
        accept: _self.acceptDel
      })
    },
    // 開啟更新視窗
    showEditDialog(item){
      this.editword = item.word
      this.checkword=item.word
      this.editid = item.id
      this.activeEdit = true
    },
    // 更新常用字句
    acceptEdit(){
      var _self = this
      if(_self.editword==_self.checkword){
        this.notify('danger',this.$t('setWord.waring'), '')
        return false ; 
      }
      _self.$vs.loading()
      _self.$store.dispatch('editWord', {'id':_self.editid, 'word': _self.editword, 'type':_self.selecttype}).then((res) => {
        _self.fetchWords()
      }).then(()=>{
        _self.activeEdit = false
        _self.editword = ''
        _self.$vs.loading.close()
      })
    },
    // 刪除常用字句
    acceptDel(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('delWord', {'id':_self.delid, 'type':_self.selecttype}).then((res) => {
        if(res.data.status=='OK'){
          _self.notify('grey', '', _self.$t('message.delete_success'))
        }else{
          _self.notify('danger', '', _self.$t('message.delete_error'))
        }
        _self.fetchWords()
        _self.$vs.loading.close()
      })
    },
    // 新增常用字句
    addWord(){
      var _self = this
      _self.$vs.loading()
      let arrayPayload = [{'type':_self.selecttype, 'word': _self.word}]
      _self.$store.dispatch('addWord', arrayPayload).then(() => {
        _self.word = ''
        _self.fetchWords()
        _self.$vs.loading.close()
      })
    },
    // 取常用字句
    fetchWords() {
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('fetchWords', _self.selecttype+'/'+_self.setwordPage).then((res) => {
        _self.setwordTotal = res.data.total
        _self.$vs.loading.close()
      })
    },
    // 選取常用字句類型
    selectType() {
      this.fetchWords()
    },
    // 多筆匯入上一部按鈕
    previousstep(){
      this.importwordstep--
    },
    // 多筆匯入下一步按鈕
    nextstep() {
      this.importwordstep++
    },
    // 檔案上傳
    ChangeUpload(event){
      let _self = this;
      var testmsg=event.name.substring(event.name.lastIndexOf('.')+1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if(!extension && !extension2) {
        _self.notify('danger', '', _self.$t('message.upload_file_error'))
        return false;
      }
      var files = event.raw
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[sheetName];
        _self.previewworddata = XLSX.utils.sheet_to_json(worksheet)
        _self.nextstep()
      };
      reader.readAsArrayBuffer(files)
    },
    // 多筆匯入
    importword(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('addWord', _self.previewworddata)
      .then(() => {
        _self.fetchWords()
        _self.$vs.loading.close()
      })
      _self.nextstep()
    },
    // 關閉匯入視窗
    closeDialogVisibleWord(){
      this.DialogVisibleWord = false
      this.importwordstep = 1
    },
    // 換頁
    pageChange(page) {
      this.setwordPage = page
      this.fetchWords()
      window.scrollTo(0,0);
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
  }
}
</script>
<style scope>
  .vs-list--title {
    font-weight: 400;
    word-break: break-word;
  }
</style>
